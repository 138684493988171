import Card from './card';
import B2E2B from '../images/newSES-card.svg';
import '../styles/service.scss';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import React, { useState } from "react";
import Necfree from './necfree.js';
import { Helmet } from "react-helmet"
import DCservice from '../images/dc-cover.svg';

function Service() {
  const [state, setState] = useState(false)
  const openDrawer = () => {setState(!state);}

  return (
    <div id="services">

      <Helmet>
        <link
          rel="canonical"
          href="https://www.necmos.jp/services" />
        <title>Services | 株式会社Necmos</title>
        <meta
          name="description"
          content="Necmosの事業紹介ページです。エンジニアのキャリアに寄り添いクライアントに正しい価値貢献をする「キャリア伴走型エージェント事業」を展開しています。" />
      </Helmet>

      <div className="services-title">
        <h3>What we do</h3>
        <h4>サービスについて</h4>
      </div>
      <div className="services-layout">
        <Card
          link="/services-b2b"
          image={B2E2B}
          title="キャリア伴走型エージェント事業"
          description="単価連動型の給与体系を採用し、個人の描くキャリアをサポートする体制のもと、お客様のニーズに最適な、自走力の高いIT人材を提供します。"/>          
        <Card
          link="/services-DC"
          image={DCservice}
          title="デジタルクリエイティブ事業"
          description="企業の価値を届けるべき人に届けるためのマーケティング戦略、他社との差別化を図る訴求力の高いクリエイティブ制作など、企業と社会、ユーザーとの永続的な関係性構築を目指した提案を行います。"/>
        <div onClick={() => openDrawer()}>
          {/* <Card
            image={Service2}
            title="開発中" /> */}
        </div>
      </div>

      <SwipeableDrawer
        classes={
          {
            root: 'necfree-drawer-root',
            paper: 'necfree-drawer-paper'
          }
        }
        anchor="bottom"
        open={state}
        onClose={() => setState(false)}
        onOpen={() => setState(false)} >
        <Necfree
        closeHandler={() => setState(false)}
        drawer={true} />
      </SwipeableDrawer>
    </div>
  );
}

export default Service;
