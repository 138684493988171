import '../styles/employment.scss';
import {BsCircleFill} from 'react-icons/bs';
import ScrollableTableImg from '../images/career-strength-03.svg';
import ScrollableTableImgForPC from '../images/career-strength-03-pc.svg';
import { Helmet } from "react-helmet"
import JobRolesLists from './jobRolesLists.js';
import ButtonWithArrow from './buttonWithArrow.js';
import CareerStrengthImg1 from '../images/career-strength-01.svg';
import CareerStrengthImg1ForPC from '../images/career-strength-01-pc.svg';
import CareerStrengthImg2 from '../images/career-strength-02.svg';
import CareerStrengthImg2ForPC from '../images/career-strength-02-pc.svg';
import AutoRotateMemberImg from './autoRotateMemberImg.js';
import CareerAccordion from './careerAccordion.js';
import careerMessageImg1 from '../images/career-message-01.svg';
import careerMessageImg2 from '../images/career-message-02.svg';
import careerMessageImg3 from '../images/career-message-03.svg';
import careerMessageImg4 from '../images/career-message-04.svg';
import careerMessageImg5 from '../images/career-message-05.svg';
import careerMessageImg6 from '../images/career-message-06.svg';
import careerMessageImg7 from '../images/career-message-07.svg';
import ViewAllLink from './viewAllLink';
import MemberInterviewCard from './memberInterviewCard.js';
import CareerInterviewImg1 from '../images/career-interview-01.webp';
import CareerInterviewImg2 from '../images/career-interview-02.webp';
import CareerInterviewImg3 from '../images/career-interview-03.webp';
import CareerInterviewImg4 from '../images/career-interview-04.webp';
import CareerInterviewImg5 from '../images/career-interview-05.webp';
import CareerInterviewImg6 from '../images/career-interview-06.webp';

function Employment() {
  return (
    <div id="career">

      <Helmet>
        <link
          rel="canonical"
          href="https://www.necmos.jp/careers" />
        <title>Careers | 株式会社Necmos</title>
        <meta
          name="description"
          content="Necmosの採用情報ページです。採用に関する情報から、福利厚生、給与情報、募集職種、メンバーインタビューなどをご覧いただけます。" />
      </Helmet>

      <div className="career-top">
        <h2>Careers</h2>
        <h3>採用情報</h3>
      </div>

      <section className="philosophy-mission-layout">
        <div className="philosophy-mission-container">
          <div className="career-head-content">
            <h3>さぁ、キャリアを欲張ろう。</h3>
            <p className="paragraph-m">
              働き方改革、副業解禁、ジョブ型雇用の拡大、社会保障崩壊の危機感などにより、個人のキャリア形成の重要度が日に日に増していく現代において、あなたの人生をより輝かしいものにするために、
              一人一人のキャリアに寄り添った、キャリア伴走型のエージェント事業を展開しております。あなたの未来を聞かせてください。
            </p>
            <AutoRotateMemberImg />
          </div>
          <div className="content">
            <div className="head-title"><BsCircleFill /><h4>Philosophy</h4></div>
            <div>
              <h3>“たがい”を愛そう</h3>
              <h5>love the differences</h5>
            </div>
          </div>
          <div className="content">
            <div className="head-title last-title"><BsCircleFill /><h4>Mission</h4></div>
            <div>
              <h3>自分らしさが誰かのためになる世界を実現する</h3>
              <h5>Create a world where being yourself is being for someone else.</h5>
            </div>
          </div>
          <ButtonWithArrow
            link="/company-culture"
            text="Necmosの文化を見る"
            bg="blue" />
        </div>
      </section>

      <section className="message-layout">
        <div className="message-container">
          <div className="career-head">
            <h4>Message</h4>
            <h3>「キャリア」をより追求できる環境にする為に。</h3>
            <p className="paragraph-m">私たちNecmosは、個人のキャリア形成を誠実にサポートすることに本気でこだわっています。</p>
          </div>
          <div className="career-accordion-lists">
            <CareerAccordion
              title="参画する案件の単価を開示"
              image={careerMessageImg1}
              text="業界では当たり前のようにブラックスボックス化されている案件単価を弊社では本人に公開しております。ご自身の市場価値を客観的に認識することがキャリアを構想することに必要不可欠だと考えているからです。" />
            <CareerAccordion
              title="単価が上がると還元率がUp"
              image={careerMessageImg2}
              text="スキルの向上やキャリアのステップアップが実現すると、単価の上昇と共に給与の還元率も上昇します。頑張ったら頑張った分だけ最大限本人に還元することで、更に今後のステップアップへの意欲に繋がると考えているからです。" />
            <CareerAccordion
              title="自ら入りたい案件を選べる"
              image={careerMessageImg3}
              text="キャリアやスキルを向上させるには、自分で案件を選択し、責任を持ってお客様の課題解決に当たっていくことが大切であると弊社では考えております。当然、幅広い案件をご提案させていただきますが、最終的にはご自身の意思を持って案件先を決めていただきます。" />
            <CareerAccordion
              title="資格祝い金で最大5万まで"
              image={careerMessageImg4}
              text="意欲的に自己研鑽に努めるメンバーへのサポートを行っております。今後更にサポート体制は拡充していく方針ですが、現段階で取り組んでいるサポートが資格取得のサポートです。様々なIT技術者向けの資格があるため、受験費用をお祝い金として会社が負担させて頂きます。" />
            <CareerAccordion
              title="社内コミュニケーション"
              image={careerMessageImg5}
              text="弊社では、SNS型のコミュニケーションツールを活用し、社内メンバーとのコミュニケーションを取っております。ナレッジ共有など、積極的な関わり合いを持とうとする社風なので、メンバー間で切磋琢磨し合える関係を築けたり、自己成長に繋がる気づきを、何気ない日常の中から見つけていくことができます。" />
            <CareerAccordion
              title="次のキャリアへの門出を支援"
              image={careerMessageImg6}
              text="Necmosでは、卒業する社員へのサポートを行っております。例えば、転職支援やフリーランス転身の際に必要な情報提供や手続きなどの支援が挙げられます。弊社での経験を通じて、キャリアのステージを上げていってもらえるよう、最後の最後までサポートをさせて頂いております。" />
            <CareerAccordion
              title="新規事業へのチャレンジ"
              image={careerMessageImg7}
              className="last"
              text="今後Necmosでは、新規事業を数多く手掛けていく方針です。0→1フェーズを経験したい。プロジェクトマネジメントをしていきたい。ITスキルだけでなく、ビジネス的な素養も身につけたい。などの意欲を持つ社員には是非とも挑戦して欲しいと考えており、チャレンジすることのできる環境が整っております。" />
          </div>
        </div>
      </section>

      <section className="strength-layout">
        <div className="strength-container">
          <h4 className="strength-en-title">Our Strength</h4>
          <h3 className="strength-ja-title">ご自身の単価に応じて給与への還元率が変動します。</h3>
          <div className="strength-paragraph">
            <p className="paragraph-m">
              Necmosの特長は、単価から社員の給与へと反映される還元率の高さにあります。
            </p>
            <p className="paragraph-m">
              還元率とは、企業間で取引される人材の単価からどれだけの金額が、本人に還元されるかを指します。
              業界では50％〜60％が平均ですが、弊社の場合、平均よりも還元率が高いです。
            </p>
            <p className="paragraph-m">
              また、弊社は還元率固定ではなく、単価から頂く会社利益を「一律で10万円」と定めているため、還元率が変動します。
              これは、還元率固定の場合、単価が高ければ会社利益も多くなるため、キャリア重視ではなく利益重視となる要素を排除するためです。
              キャリア重視だからこそ、本人が希望する案件に参画することができます。
            </p>
          </div>

          <div className="strength-list-content">
            <h4><BsCircleFill />会社利益を一律で10万に固定</h4>
            <img
              className="img-with-box-shadow for-sp"
              src={CareerStrengthImg1} />
            <img
              className="img-with-box-shadow for-pc"
              src={CareerStrengthImg1ForPC} />
          </div>
          <div className="strength-list-content">
            <h4><BsCircleFill />実際の給与のイメージ</h4>
            <img
              className="for-sp"
              src={CareerStrengthImg2} />
            <img
              className="for-pc"
              src={CareerStrengthImg2ForPC} />
            <span className="notes">※会社負担保険料抜き</span>
          </div>
          <div className="strength-list-content">
            <h4><BsCircleFill />他社との比較</h4>
            <div className="scrollable-content">
              <img
                className="scroll-table-img for-sp"
                src={ScrollableTableImg} />
              <img
                className="scroll-table-img for-pc"
                src={ScrollableTableImgForPC} />
            </div>
            <span className="notes">※他社との比較のため、会社負担保険料込みの還元率にて計算をしております。</span>
          </div>
        </div>
      </section>

      <section className="member-interviews-layout">
        <div className="member-interviews-container">
          <div className="member-interviews-head">
            <h4>Member Interviews</h4>
            <h3>社員インタビュー <ViewAllLink isDiagonal={true} link="https://www.wantedly.com/companies/necmos/stories" /></h3>
          </div>
          <div className="member-interview-cards-container">
            <div className="member-interview-cards">
              <MemberInterviewCard
                image={CareerInterviewImg1}
                link="https://www.wantedly.com/companies/necmos/post_articles/397795"
                occupation="エンジニア"
                color="orange"
                description="資格を取る事が目標という訳じゃないんです"
                name="Mayumi Kotagiri / インフラエンジニア" />
              <MemberInterviewCard
                image={CareerInterviewImg2}
                link="https://www.wantedly.com/companies/necmos/post_articles/393958 "
                occupation="エンジニア"
                color="green"
                description="自分自身に強い芯を持つ"
                name="Naoki Egami / インフラエンジニア" />
            </div>
            <div className="member-interview-cards">
              <MemberInterviewCard
                image={CareerInterviewImg3}
                link="https://www.wantedly.com/companies/necmos/post_articles/396029"
                occupation="セールス"
                color="blue"
                description="自身の介入によって価値を還元したい"
                name="Toshihiro Matsushima / ITキャリアアドバイザー" />
              <MemberInterviewCard
                image={CareerInterviewImg4}
                link="https://www.wantedly.com/companies/necmos/post_articles/398451"
                occupation="エンジニア"
                color="pink"
                description="自分のためになる事は積極的にチャレンジしていくことが大事"
                name="Sho Ogasawara / バックエンドエンジニア" />
            </div>
            <div className="member-interview-cards last-cards">
              <MemberInterviewCard
                image={CareerInterviewImg5}
                link="https://www.wantedly.com/companies/necmos/post_articles/393978"
                occupation="取締役"
                color="green"
                description="みんな違ってみんな良い"
                name="Yuki Senga / 取締役" />
              <MemberInterviewCard
                image={CareerInterviewImg6}
                link="https://www.wantedly.com/companies/necmos/post_articles/393981"
                occupation="代表取締役"
                color="blue"
                description="自分の持っている個性を大切にしてほしい"
                name="Taiichiro Kubota / 代表取締役" />
            </div>
          </div>
        </div>
      </section>

      <section className="jobs-layout">
        <div className="jobs-head-message">
          <h4>Jobs</h4>
          <h3>募集中の職種</h3>
        </div>
        <JobRolesLists />
        <ButtonWithArrow
          link="https://jp.indeed.com/necmos%E9%96%A2%E9%80%A3%E3%81%AE%E6%B1%82%E4%BA%BA"
          text="全ての職種を見る"
          bg="blue"
          targetBlank="true"
          isDiagonal="true" />
      </section>
    </div>
  );
}

export default Employment;
