import '../styles/memberInterviewCard.scss';

function MemberInterviewCard(props) {
  return (
    <a
      href={props.link}
      target="_blank"
      rel="noopener noreferrer nofollow"
      className="member-interview-card">
      <div className="image-content">
        <img src={props.image} />
        <span className={"occupation " + props.color}>{props.occupation}</span>
      </div>
      <span className="description">{props.description}</span>
      <span className="name">{props.name}</span>
    </a>
  );
}

export default MemberInterviewCard;
