import '../styles/careerFieldButton.scss';
import DiagonalArrow from '../images/diagonal-arrow-blue.svg';

function CareerFieldButton(props) {
  return (
    <a
      href={props.link}
      target="_blank"
      rel="noopener noreferrer nofollow"
      className="career-field-button">
      {props.content}
      <img src={DiagonalArrow} />
    </a>
  );
}

export default CareerFieldButton;
