import '../styles/companyOverview.scss';
import NcmsLogo from '../images/ncms-logo-company-profile.svg'
import {BsCircleFill} from 'react-icons/bs'
import Card from './card.js';
import CeoImg from '../images/ceo-message_kubota.webp';
import NcmsCulture from '../images/culture.webp';
import AccessToOffice from '../images/access-to-office.png';
import KubotaSanImg from '../images/company-profile_kubota.webp';
import SengaSanImg from '../images/company-profile_senga.webp';
import OkudaSanImg from '../images/company-profile_okuda.webp';
import { Helmet } from "react-helmet"

function CompanyOverview() {
  return (
    <div id="company-overview">

      <Helmet>
        <link
          rel="canonical"
          href="https://www.necmos.jp/company-profile" />
        <title>会社概要 | Company | 株式会社Necmos</title>
        <meta
          name="description"
          content="Necmosの会社概要ページです。所在地、事業内容などの企業基本情報、経営陣のプロフィールをご覧いただけます。" />
      </Helmet>

      <div className="company-info-title">
        <h3>Company Profile</h3>
        <h4>会社概要</h4>
      </div>

      <div className="overview-lists">
        <div className="overview-lists-container">
          <img
            className="ncms-logo"
            src={NcmsLogo} />
          <ul>
            <li>
              <h5>会社名</h5>
              <p>株式会社Necmos</p>
            </li>
            <li>
              <h5>所在地</h5>
              <p>
                〒151-0053<br />
                東京都渋谷区代々木1-30-14
                天翔オフィス代々木ANNEX
                206号室
              </p>
            </li>
            <li>
              <h5>設立</h5>
              <p>2021年1月7日</p>
            </li>
            <li>
              <h5>資本金</h5>
              <p>900万円</p>
            </li>
            <li>
              <h5>代表者名</h5>
              <p>
                代表取締役<br />
                久保田泰一郎
              </p>
            </li>
            <li>
              <h5>従業員数</h5>
              <p>40名</p>
            </li>
            <li>
              <h5>事業内容</h5>
              <p>キャリア伴走型エージェント事業<br />
              デジタルクリエイティブ事業</p>
            </li>
            <li>
              <h5>主要取引銀行</h5>
              <p>みずほ銀行、楽天銀行</p>
            </li>
            <li>
              <h5>加盟団体</h5>
              <p>関東ITソフトウェア健康保険組合</p>
            </li>
          </ul>
        </div>
      </div>

      <div className="members">
        <div className="members-container">
          <h4><BsCircleFill />Management</h4>
          <div className="member-introduction-container">
            <div className="member-introduction">
              <img src={KubotaSanImg} />
              <p className="position">代表取締役</p>
              <p className="name-ja">久保田 泰一郎</p>
              <p className="name-en">Taiichiro Kubota</p>
              <p className="explain paragraph-m">
                2019年成蹊大学卒業後、レバレジーズ株式会社に新卒入社。
                新規事業立ち上げメンバーとして、M&Aアドバイザリー事業部に加入。
                主にIT企業を対象に、M&Aアドバイザーとして従事しつつ、新規開拓やバックオフィスの実行面から業務フロー設計などに幅広く携わる。
                その後、株式会社Necmosを創業し、キャリア伴走型エージェント事業を開始。
              </p>
            </div>
            <div className="member-introduction">
              <img src={SengaSanImg} />
              <p className="position">取締役</p>
              <p className="name-ja">千賀 勇輝</p>
              <p className="name-en">Yuki Senga</p>
              <p className="explain paragraph-m">
                2019年関西外国語大学卒業。
                株式会社ネオキャリアに新卒入社し、子会社の立ち上げに参画。求人広告の代理店として提案営業を中心に業務を遂行。
                その後、2020年7月より成果報酬型Web広告代理店に転職し、引き続き営業職として従事。
                その後2021年1月に株式会社Necmosを創業。
              </p>
            </div>
            <div className="member-introduction">
              <img src={OkudaSanImg} />
              <p className="position">取締役</p>
              <p className="name-ja">奥田 智也</p>
              <p className="name-en">Tomoya Okuda</p>
              <p className="explain paragraph-m">
                2021年早稲田大学卒業。在学中にフォトグラファー/UI•UXデザイナーとして、ECサイトのデザイン/ディレクションやコーポレートサイトの制作に携わる。
                他にもクリエイティブ情報メディアの立ち上げ/運営に携わるなど幅広く活動。
                その後、株式会社Necmosを創業。
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="more-information">
        <h4 className="more-information-title">More Information</h4>
        <Card
          link="/company-message"
          image={CeoImg}
          title="代表あいさつ" />
        <Card
          link="/company-culture"
          image={NcmsCulture}
          title="Necmosの文化" />
        <Card
          link="/company-access"
          image={AccessToOffice}
          title="オフィスへの行き方" />
      </div>
    </div>
  );
}

export default CompanyOverview;
