import '../styles/top.scss';
import {BsCircleFill} from 'react-icons/bs';
import ButtonWithArrow from './buttonWithArrow.js';
import Necfree from './necfree.js';
import Card from './card.js';
import B2E2B from '../images/newSES-card.svg';
import dc from '../images/dc-cover-footer.svg';
import React, { useState } from "react";
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import CeoImg from '../images/ceo-message_kubota.webp';
import NcmsCulture from '../images/culture.webp';
import AccessToOffice from '../images/access-to-office.png';
import NcmsBackground from '../images/ncms-background.svg';
import { Helmet } from "react-helmet"
import ViewAllLink from './viewAllLink.js';
import HPAnimation from '../images/HP_animation.mp4';
import HPAnimationForSP from '../images/HP_animation_for_sp.mp4';

function Top() {
  const [state, setState] = useState(false)
  const openDrawer = () => {setState(!state);}

  return (
    <div>

      <Helmet>
        <link
          rel="canonical"
          href="https://www.necmos.jp/" />
        <title>株式会社Necmos</title>
        <meta
          name="description"
          content="株式会社Necmosは「”たがい”を愛そう /love the differences」を理念に掲げ、エンジニアの人材サービスを展開するIT企業です。自分らしさが誰かのためになる世界の実現を目指します。" />
      </Helmet>

      <div id="top">
        <section className="top-message">
          <div class="for-sp">
            {/* mutedをつけないとスマホで自動再生できないため、やむなくdangerouslySetInnerHTMLを使用 */}
            <div dangerouslySetInnerHTML={{__html: `<video src=${HPAnimationForSP} loop autoplay playsinline muted />`}} />
          </div>
          <div class="for-pc">
            {/* mutedをつけないとスマホで自動再生できないため、やむなくdangerouslySetInnerHTMLを使用 */}
            <div dangerouslySetInnerHTML={{__html: `<video src=${HPAnimation} loop autoplay playsinline muted />`}} />
          </div>
        </section>

        <section className="mission">
          <div className="grid-when-pc">
            <div className="mission-head-text"><BsCircleFill /><h4>Philosophy</h4></div>
            <div>
              <h4 className="mission-title">“たがい”を愛そう</h4>
              <h5>love the differences</h5>
            </div>

            <div className="mission-head-text"><BsCircleFill /><h4>Mission</h4></div>
            <div>
              <h4 className="mission-title">自分らしさが誰かのためになる世界を実現する</h4>
              <h5>Create a world where being yourself is being for someone else.</h5>
            </div>
          </div>

          <ButtonWithArrow
            link="/company-culture"
            text="Necmosの文化を見る"
            bg="white" />
        </section>

        <section className="services-content">
          <div className="services-title">
            <h4>What we do</h4>
            <h3>事業内容</h3>
          </div>
          <div className="for-sp">
            <div className="services-layout">
              <Card
                link="/services-b2b"
                image={B2E2B}
                title="キャリア伴走型エージェント事業"
                description="単価連動型の給与体系を採用し、個人の描くキャリアを全力でサポートする体制のもと、成長意欲や自走力の高いIT人材を提供します" />
              <Card
                link="/services-DC"
                image={dc}
                title="デジタルクリエイティブ事業"
                description="企業の価値を届けるべき人に届けるためのマーケティング戦略、他社との差別化を図る訴求力の高いクリエイティブ制作など、企業と社会、ユーザーとの永続的な関係性構築を目指した提案を行います。" />
            <ButtonWithArrow
              link="/services"
              text="サービスページを見る"
              bg="blue" />

            <SwipeableDrawer
              classes={
                {
                  root: 'necfree-drawer-root',
                  paper: 'necfree-drawer-paper'
                }
              }
              anchor="bottom"
              open={state}
              onClose={() => setState(false)}
              onOpen={() => setState(false)} >
              <Necfree
              closeHandler={() => setState(false)}
              drawer={true} />
            </SwipeableDrawer>
          </div>
        </div>

          {/* PC */}
          <div className="for-pc">
            <div className="Service-container">
              <img
                src={B2E2B}
                className="b2e2b-img"/>
              <div className="right-content">
                <h4 className="career-agent-service-title">キャリア伴走型エージェント事業</h4>
                <p className="career-agent-service-description">
                単価連動型の給与体系を採用し、個人の描くキャリアをサポートする体制のもと、お客様のニーズに最適な、自走力の高いIT人材を提供します。
                </p>
                <ButtonWithArrow
                  link="/services-b2b"
                  text="事業内容を見る"
                  bg="blue" />
              </div>
            </div>
            <div className="Service-container">
              <img
                src={dc}
                className="b2e2b-img"/>
              <div className="right-content">
                <h4 className="career-agent-service-title">デジタルクリエイティブ事業</h4>
                <p className="career-agent-service-description">
                企業の価値を届けるべき人に届けるためのマーケティング戦略、他社との差別化を図る訴求力の高いクリエイティブ制作など、企業と社会、ユーザとの永続的な関係性構築を目指した提案を行います。
                </p>
                <ButtonWithArrow
                  link="/services-DC"
                  text="事業内容を見る"
                  bg="blue" />
              </div>
            </div>
          </div>
        </section>

        <section className="company-info-layout">
          <div className="company-info-title">
            <h4>Company</h4>
            <div className="contents">
              <h3>会社情報</h3>
              <ViewAllLink link="/company" />
            </div>
          </div>
          <div className="company-cards-horizontal-list">
            <div
              id="card"
              className="for-pc for-pc-card-basis" />
            <Card
              link="/company-profile"
              image={NcmsBackground}
              title="会社概要" />
            <Card
              link="/company-message"
              image={CeoImg}
              title="代表あいさつ" />
            <Card
              link="/company-culture"
              image={NcmsCulture}
              title="Necmosの文化" />
            <Card
              link="/company-access"
              image={AccessToOffice}
              title="オフィスへの行き方" />
            <div
              id="card"
              className="for-pc for-pc-card-tail" />
          </div>
        </section>
      </div>
    </div>
  );
}

export default Top;
