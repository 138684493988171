import '../styles/autoRotateMemberImg.scss';
import Member1 from '../images/career-top-01.webp';
import Member2 from '../images/career-top-02.webp';
import Member3 from '../images/career-top-03.webp';
import Member4 from '../images/career-top-04.webp';
import Member5 from '../images/career-top-05.webp';
import Member6 from '../images/career-top-06.webp';

function AutoRotateMemberImg() {
  // 画像の永遠ループ。以下のブログを参考に作成↓
  // https://chocolat5.com/tips/loop-image-animation/
  return (
    <div className="member-img-loop-wrap">
      <ul>
        <li><img src={Member1} /></li>
        <li><img src={Member2} /></li>
        <li><img src={Member3} /></li>
        <li><img src={Member4} /></li>
        <li><img src={Member5} /></li>
        <li><img src={Member6} /></li>
      </ul>
      <ul>
        <li><img src={Member1} /></li>
        <li><img src={Member2} /></li>
        <li><img src={Member3} /></li>
        <li><img src={Member4} /></li>
        <li><img src={Member5} /></li>
        <li><img src={Member6} /></li>
      </ul>
    </div>
  );
}

export default AutoRotateMemberImg;
