import '../styles/card.scss';
import {MdKeyboardArrowRight} from 'react-icons/md';
function Card(props) {
  return (
    <div id="card">
      <a href={props.link}>
        <img src={props.image}/>
        {/* <div className={props.description ? "bottom has-description" : "bottom"}>
          <div>
            <p className="title">{props.title}</p>
          </div>
          <MdKeyboardArrowRight className={props.description ? "arrow-right has-description" : "arrow-right"} />
        </div> */}
      </a>
      <div className={props.description ? "bottom has-description" : "bottom"}>
        <div>
          <p className="title">{props.title}</p>
          <p className="description">{props.description}</p>
        </div>
        <MdKeyboardArrowRight className={props.description ? "arrow-right has-description" : "arrow-right"} />
      </div>
    </div>
  );
}

export default Card;
