import '../styles/footer.scss';
import React, { useState } from "react";
import NcmsLogoWhite from '../images/Ncms-logo-white.svg';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import {MdAdd, MdRemove, MdKeyboardArrowRight, MdKeyboardArrowDown} from 'react-icons/md';
import ChatIcon from '../images/chat-icon.svg';
import NcmsIcon from '../images/ncms-icon.svg';
import B2E2B from '../images/newSES-card.svg';
import Service02 from '../images/dc-cover-footer.svg';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Necfree from './necfree.js';
import {IoMdClose} from 'react-icons/io';
import DiagonalArrow from '../images/diagonal-arrow-pc.svg';
import DiagonalArrowHover from '../images/diagonal-arrow-hover.svg';

function Footer(props) {
  const [expanded, setExpanded] = useState(true);
  const [state, setState] = useState(false)
  const openDrawer = () => {setState(!state);}
  const showContactForms = function() {
    return (
      <div className="contact-forms">
        <a
          href="https://share.hsforms.com/1bwASZ7OpQ_ye-7H_Ax3xlwrrpdj"
          target="_blank"
          rel="noopener noreferrer nofollow">
          <img src={ChatIcon} />
          <p className="head-ja">ご相談はこちら</p>
          <p className="head-en">Contact</p>
          <p className="detail">ITエンジニアのアサインに関するご相談はこちらから</p>
          <MdKeyboardArrowDown className="arrow-icon" />
        </a>
        <a href="https://www.career.necmos.jp/">
          <img src={NcmsIcon} />
          <p className="head-ja">共に働きませんか？</p>
          <p className="head-en">Join us!</p>
          <p className="detail">会社の特徴、給与情報、募集職種に関する情報はこちらから</p>
          <MdKeyboardArrowDown className="arrow-icon" />
        </a>
      </div>
    );
  }
  const showCloseButton = function() {
    return(
      <IoMdClose
        className="close-button"
        onClick={() => {props.closeMenuHandler()}} />
    );
  }

  return(
    <footer id="footer">
      <div className="for-sp">
        {!props.drawer && showContactForms()}
        <div className={props.drawer ? "menu drawer" : "menu"}>
          <a href="/">
            <img src={NcmsLogoWhite} className="ncms-logo" />
          </a>

          <div className="menu-list">
            <a href="/services">
              <p>事業内容</p>
              <MdKeyboardArrowRight className="arrow-icon" />
            </a>
          </div>

          <div className="services">
            <a href="/services-b2b">
              <img src={B2E2B} />
              <p className="label">サービス01</p>
              <p className="title">キャリア伴走型<br />エージェント事業</p>
            </a>
            <a href="/services-DC">
              <img src={Service02} />
              <p className="label">サービス02</p>
              <p className="title">デジタル<br />クリエイティブ事業</p>
            </a>
          </div>

          <Accordion
            className="accordion-layout"
            sx={{
              boxShadow: 'none',
              '&:before': {
                backgroundColor: 'transparent'
              }
            }}>
            <AccordionSummary
              expandIcon={expanded ? <MdAdd className="accordion-icon" /> : <MdRemove className="accordion-icon" />}
              aria-controls="panel1a-content"
              onClick={(e) => { setExpanded(!expanded) }}
              className="accordion-summary" >
              <p>会社情報</p>
            </AccordionSummary>
            <AccordionDetails>
              <ul className="accordion-details">
                <li>
                  <a href="/company-profile">
                    <p>会社概要</p>
                    <MdKeyboardArrowRight className="arrow-icon" />
                  </a>
                </li>
                <li>
                  <a href="/company-message">
                    <p>代表あいさつ</p>
                    <MdKeyboardArrowRight className="arrow-icon" />
                  </a>
                </li>
                <li>
                  <a href="/company-culture">
                    <p>Necmosの文化</p>
                    <MdKeyboardArrowRight className="arrow-icon" />
                  </a>
                </li>
                <li>
                  <a href="/company-access">
                    <p>オフィスへの行き方</p>
                    <MdKeyboardArrowRight className="arrow-icon" />
                  </a>
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>

          <div className="menu-list">
            <a href="https://www.career.necmos.jp/">
              <p>採用情報</p>
              <MdKeyboardArrowRight className="arrow-icon" />
            </a>
          </div>
          <div className="menu-list">
            <a
              href="https://share.hsforms.com/1bwASZ7OpQ_ye-7H_Ax3xlwrrpdj"
              target="_blank"
              rel="noopener noreferrer nofollow" >
              <p>ご相談はこちら</p>
              <MdKeyboardArrowRight className="arrow-icon" />
            </a>
          </div>

          <small>©️ 2024 Necmos Inc. | 株式会社  Necmos</small>

          {props.drawer && showCloseButton()}
        </div>

        <SwipeableDrawer
          classes={
            {
              root: 'necfree-drawer-root-for-bottom-menu',
              paper: 'necfree-drawer-paper-for-bottom-menu'
            }
          }
          anchor="bottom"
          open={state}
          onClose={() => setState(false)}
          onOpen={() => setState(false)} >
          <Necfree
          closeHandler={() => setState(false)}
          drawer={true} />
        </SwipeableDrawer>
      </div>

      {/* 以下よりPC画面 */}

      <div className="for-pc">
        {showContactForms()}
        <div className="menu-pc">
          <div className="pc-content">
            <a
              className="ncms-logo-link"
              href="/">
              <img src={NcmsLogoWhite} className="ncms-logo" />
            </a>

            <div className="menu-content">
              <div className="left-content">
                <div className="map-and-copyright">
                  <span className="address">〒151-0053 東京都渋谷区代々木1-30-14天翔オフィス代々木ANNEX206号室</span>
                  <a
                    className="map-link"
                    href="https://goo.gl/maps/7bFDNKHHz7w7RBAc6"
                    target="_blank"
                    rel="noopener noreferrer nofollow">
                    Google Map
                    <img
                      className="arrow"
                      src={DiagonalArrow} />
                    <img
                      className="arrow-hover"
                      src={DiagonalArrowHover} />
                  </a>
                  <div className="copyright">
                    <small>©️ 2024 Necmos Inc. | 株式会社  Necmos</small>
                  </div>
                </div>
                <div className="services">
                  <a
                    href="/services"
                    className="list-title">
                      事業内容
                  </a>
                  <a
                    className="service-item service-item-1"
                    href="/services-b2b">
                    <img
                      className="b2e2b"
                      src={B2E2B} />
                    <div className="b2e2b-hover" />
                    <div>
                      <span className="head">サービス01</span>
                      <span className="description">キャリア伴走型<br />エージェント事業</span>
                    </div>
                  </a>
                  <a
                    className="service-item service-item-2"
                    href="/services-DC">
                    <img 
                      className="dc"
                      src={Service02} />
                    <div className="dc-hover" />
                    <div>
                      <span className="head">サービス02</span>
                      <span className="description">デジタル<br />クリエイティブ事業</span>
                    </div>
                  </a>
                </div>
              </div>

              <div className="right-content">
                <div className="company-list">
                  <div>
                    <a
                      href="/company"
                      className="list-title">
                      会社情報
                    </a>
                  </div>
                  <a
                    href="/company-profile"
                    className="list-item">
                    会社概要
                  </a>
                  <a
                    href="/company-message"
                    className="list-item">
                    代表あいさつ
                  </a>
                  <a
                    href="/company-culture"
                    className="list-item">
                    Necmosの文化
                  </a>
                  <a
                    href="/company-access"
                    className="list-item">
                    オフィスへの行き方
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.career.necmos.jp/"
                    className="list-title" style={{display: "flex"}}>
                    採用情報
                    <img
                      className="arrow"
                      src={DiagonalArrow} />
                    <img
                      className="arrow-hover"
                      src={DiagonalArrowHover} />
                  </a>
                </div>
                <div>
                  <a
                    href="https://share.hsforms.com/1bwASZ7OpQ_ye-7H_Ax3xlwrrpdj"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    className="list-title">
                    ご相談はこちら
                  </a>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
