import '../styles/agentService.scss';
import AgentServiceTop from '../images/newSES-card.svg';
import ButtonWithArrow from './buttonWithArrow.js';
import SESImg from '../images/SES図for-svg.svg';
import SESImgForPC from '../images/service-ses-pc.svg';
import {BsCircleFill} from 'react-icons/bs';
import { Helmet } from "react-helmet";
import CareerAccordion from "./careerAccordion.js";
import CheckIcon from "../images/career-check-icon.svg";
import CareerJiritsuImg from '../images/career-jiritsu.svg';
import CareerJiritsuImgForPC from '../images/career-jiritsu-pc.svg';
import DbIcon from '../images/db-icon.svg';
import StepsIcon from '../images/career-message-02.svg';
import FingerIcon from '../images/career-message-03.svg';
import ChatIcon from '../images/career-message-05.svg';
import NcmsIcon from '../images/career-message-07.svg';
import careerMessageImg1 from '../images/career-message-01.svg';
import careerMessageImg2 from '../images/career-message-02.svg';
import careerMessageImg3 from '../images/career-message-03.svg';
import careerMessageImg4 from '../images/career-message-04.svg';
import careerMessageImg5 from '../images/career-message-05.svg';
import careerMessageImg6 from '../images/career-message-06.svg';
import careerMessageImg7 from '../images/career-message-07.svg';

function AgentService() {
  return (
    <div id="agent-service">

      <Helmet>
        <link
          rel="canonical"
          href="https://www.necmos.jp/services-b2b" />
        <title>キャリア伴走型エージェント事業 | Services | 株式会社Necmos</title>
        <meta
          name="description"
          content="単価連動型の給与体系を採用し、個人の描くキャリアをサポートする体制のもと、お客様のニーズに最適な、自走力の高いIT人材を提供します。" />
      </Helmet>

      <div className="services-title">
        <h3>キャリア伴走型<br />エージェント事業</h3>
      </div>
      <div className="agent-service-layout">
        <div className="agent-service-container">
          <img
            className="agent-service-img"
            src={AgentServiceTop} />

          <div className="content">
            <h4 className="content-h4">サービス概要</h4>
            <h3>キャリア伴走型エージェント事業とは</h3>
            <p className="agent-service-description">
              弊社のビジネスモデルは「B2E2B ＝ Business(企業) to Employee(社員) to Business(企業)」です。<br />
              実際にお客様の課題解決を行うのは、現場で課題と向き合う、一人ひとりの技術者です。
              そのため弊社では、社員に対して「キャリアプラン/ライフプランへの納得感を持って頂くこと」に重きを置いております。
              人材のパフォーマンス品質を高めることで、お客様への価値貢献を最大化させます。
            </p>

            <img
              className="for-sp"
              src={SESImg} />
            {/* PC用 */}
            <img
              className="for-pc"
              src={SESImgForPC} />

            <h4 className="service-merit"><BsCircleFill />サービスのメリット</h4>

            <div className="accordion-container">
              <CareerAccordion
                title="スピーディにリソースを確保"
                image={CheckIcon}
                text="人材紹介サービスなどを利用し、人的リソースを確保する場合と比べて、スピード感を持って必要な人材のご提案が可能です。弊社サービスでは、早ければ依頼があった翌日から参画することも可能です。" />
              <CareerAccordion
                title="雇用リスクの回避"
                image={CheckIcon}
                text="弊社サービスは、プロジェクト単位でのご契約になるため、正社員登用などと比較し、厳しい解雇規制を受けません。お客様のニーズに合致した人材を必要な時に必要な期間だけ、アサインすることが可能です。" />
              <CareerAccordion
                title="人材育成のコスト削減"
                image={CheckIcon}
                text="自社で人材を育成する場合、どうしても教育コストがかかってしまいます。目に見える金銭面や工数面だけでなく、機会損失の出てしまうリスクが大きいです。弊社サービスをご利用の場合、お客様の課題に見合った人材をご提供させて頂くため、育成コストを大幅に削減することができます。" />
              <CareerAccordion
                title="仕様変更に対応しやすい"
                image={CheckIcon}
                text="「期日までに成果物を納品する」契約形態で発注を行う場合、仕様変更への対応が難しいです。それに比べ、「決められた期間業務を行う」契約形態の弊社サービスであれば、柔軟に仕様変更に対応することが可能です。" />
            </div>

            <ButtonWithArrow
              link="https://share.hsforms.com/1bwASZ7OpQ_ye-7H_Ax3xlwrrpdj"
              targetBlank={true}
              text="ご相談はこちらから"
              bg="blue" />
          </div>
        </div>
      </div>

      <div className="service-detail-layout">
        <div className="service-detail-container">
          <div className="content">
            <h4 className="content-h4">Necmosの強み</h4>
            <h3>キャリア自律度の高い人材</h3>
            <p>
              キャリア自律とは、「めまぐるしく変化する環境の中で、自らのキャリア構築と継続的学習に積極的に取り組む、生涯にわたるコミットメント」と定義づけられております。<br /><br />
              弊社の人材は、ただ単に、保有するスキルを使って、与えられた業務をこなすだけではありません。
              課題解決のプロセスを自身のキャリア構築や学習の機会と捉えているからこそ、能動的にお客様の本質的な課題解決を行って参ります。
            </p>

            <h4 className="service-detail-h4"><BsCircleFill /><span>キャリア自律が高いとどうなるか？</span></h4>
            <p className="service-detail-description">
              キャリア自律度が高い層は低い層に比べて、「個人パフォーマンス(自己評価)」で1.20倍、仕事への貢献意欲である「ワーク・エンゲージメント」で1.27倍、「学習意欲」で1.28倍高く、キャリア自律度の向上は組織や顧客の課題解決にメリットをもたらすことが判明しております。
            </p>
            <img
              className="for-sp"
              src={CareerJiritsuImg} />
            {/* PC用 */}
            <img
              className="for-pc"
              src={CareerJiritsuImgForPC} />
            <p className="img-reference">※出所：パーソル総合研究所「従業員のキャリア自律に関する定量調査」</p>

            <h4 className="service-detail-h4"><BsCircleFill /><span>なぜNecmosの人材はキャリア自律度が高いのか？</span></h4>
            <div className="accordion-container">
              <CareerAccordion
                title="豊富なデータベース"
                image={DbIcon}
                text="様々なキャリアのヒアリング調査を行い、社内で独自のデータベースを構築しております。日々、キャリアに関する情報収集を行っており、ナレッジとして蓄積しているNecmosだからこそ、自律的なキャリア構築を支援することができております。" />
              <CareerAccordion
                title="オープンなコミュニケーション"
                image={ChatIcon}
                text="SNS型のコミュニケーションツールを活用しているため、社内メンバー間のコミュニケーションが活発です。他のメンバーと積極的に関わりながら、互いに尊重し高め合う文化があります。" />
              <CareerAccordion
                title="案件選択の自由"
                image={FingerIcon}
                text="キャリアやスキルを向上させるためには、自分で案件を選択し、責任を持ってお客様の課題解決に当たっていくことが大切であると弊社では考えております。" />
              <CareerAccordion
                title="キャリア連動型の単価制度"
                image={StepsIcon}
                text="スキルの向上やキャリアのステップアップが実現すると、単価の上昇と共に給与の還元率も上昇します。頑張ったら頑張った分だけ最大限本人に還元することで、更に今後のステップアップへの意欲に繋がると考えているからです。" />
              <CareerAccordion
                title="良い古巣でありたい"
                image={NcmsIcon}
                text="弊社を通じてキャリアを構想し、経験を積んでいくことで、辿り着く先が転職や独立であっても、気持ちよく背中を押していきたいと思っております。Necmosで良い経験が積めたからこそ、今輝いている自分がいると思ってもらえるような事業運営を心がけております。" />
            </div>
            <ButtonWithArrow
              link="https://share.hsforms.com/1bwASZ7OpQ_ye-7H_Ax3xlwrrpdj"
              targetBlank={true}
              text="ご相談はこちらから"
              bg="blue" />
          </div>
        </div>
      </div>

      <div className="service-process-layout">
        <div className="service-process-container">
          <div className="content">
            <h4 className="content-h4 service-process-heading"><BsCircleFill />サービスの流れ</h4>

            <div className="step-lists">
              <div className="step-container">
                <h5>ー STEP 1</h5>
                <h3>ヒアリング</h3>
                <p>まずはヒアリングのお時間を頂いてプロジェクトの全体像を理解させてください。</p>
              </div>
              <div className="step-container">
                <h5>ー STEP 2</h5>
                <h3>エンジニアのご紹介</h3>
                <p>ヒアリングをもとに、プロジェクトにマッチするスキルベースにてエンジニアをご紹介させていただきます。</p>
              </div>
              <div className="step-container">
                <h5>ー STEP 3</h5>
                <h3>エンジニアとの面談</h3>
                <p>共にお仕事をさせていただく中でのコミュニケーションやプロジェクトとのマッチング具合をご確認いただくため、面談を設けさせていただきます。</p>
              </div>
              <div className="step-container">
                <h5>ー STEP 4</h5>
                <h3>ご成約</h3>
                <p>お客様と当社エンジニアの諸条件がマッチした場合に、ご成約となります。</p>
              </div>
              <div className="step-container">
                <h5>ー STEP 5</h5>
                <h3>アサイン</h3>
                <p>実際にプロジェクトへのアサインとなります。</p>
              </div>
              <div className="step-container">
                <h5>ー STEP 6</h5>
                <h3>アフターフォロー</h3>
                <p>参画後の更なるパフォーマンス向上のため、当社エンジニアと定期的な1on1MTGを行い、業務態度や業務効率の改善に務め、情報共有致します。</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="message-layout">
        <div className="message-container">
          <div className="career-head">
            <h4>Message</h4>
            <h3>「キャリア」をより追求できる環境にする為に。</h3>
            <p className="paragraph-m">私たちNecmosは、個人のキャリア形成を誠実にサポートすることに本気でこだわっています。</p>
          </div>
          <div className="career-accordion-lists">
            <CareerAccordion
              title="参画する案件の単価を開示"
              image={careerMessageImg1}
              text="業界では当たり前のようにブラックスボックス化されている案件単価を弊社では本人に公開しております。ご自身の市場価値を客観的に認識することがキャリアを構想することに必要不可欠だと考えているからです。" />
            <CareerAccordion
              title="単価が上がると還元率がUp"
              image={careerMessageImg2}
              text="スキルの向上やキャリアのステップアップが実現すると、単価の上昇と共に給与の還元率も上昇します。頑張ったら頑張った分だけ最大限本人に還元することで、更に今後のステップアップへの意欲に繋がると考えているからです。" />
            <CareerAccordion
              title="自ら入りたい案件を選べる"
              image={careerMessageImg3}
              text="キャリアやスキルを向上させるには、自分で案件を選択し、責任を持ってお客様の課題解決に当たっていくことが大切であると弊社では考えております。当然、幅広い案件をご提案させていただきますが、最終的にはご自身の意思を持って案件先を決めていただきます。" />
            <CareerAccordion
              title="資格祝い金で最大5万まで"
              image={careerMessageImg4}
              text="意欲的に自己研鑽に努めるメンバーへのサポートを行っております。今後更にサポート体制は拡充していく方針ですが、現段階で取り組んでいるサポートが資格取得のサポートです。様々なIT技術者向けの資格があるため、受験費用をお祝い金として会社が負担させて頂きます。" />
            <CareerAccordion
              title="社内コミュニケーション"
              image={careerMessageImg5}
              text="弊社では、SNS型のコミュニケーションツールを活用し、社内メンバーとのコミュニケーションを取っております。ナレッジ共有など、積極的な関わり合いを持とうとする社風なので、メンバー間で切磋琢磨し合える関係を築けたり、自己成長に繋がる気づきを、何気ない日常の中から見つけていくことができます。" />
            <CareerAccordion
              title="次のキャリアへの門出を支援"
              image={careerMessageImg6}
              text="Necmosでは、卒業する社員へのサポートを行っております。例えば、転職支援やフリーランス転身の際に必要な情報提供や手続きなどの支援が挙げられます。弊社での経験を通じて、キャリアのステージを上げていってもらえるよう、最後の最後までサポートをさせて頂いております。" />
            <CareerAccordion
              title="新規事業へのチャレンジ"
              image={careerMessageImg7}
              className="last"
              text="今後Necmosでは、新規事業を数多く手掛けていく方針です。0→1フェーズを経験したい。プロジェクトマネジメントをしていきたい。ITスキルだけでなく、ビジネス的な素養も身につけたい。などの意欲を持つ社員には是非とも挑戦して欲しいと考えており、チャレンジすることのできる環境が整っております。" />
          </div>
        </div>
      </section>

    </div>
  );
}

export default AgentService;
