import '../styles/access.scss';
import Card from './card.js';
import NcmsBackground from '../images/ncms-background.svg';
import CeoImg from '../images/ceo-message_kubota.webp';
import NcmsCulture from '../images/culture.webp';
import React, {useEffect} from 'react';
import $ from 'jquery';
import MapPin from '../images/map-pin.svg';
import ArrowUp from '../images/arrow-up.svg';
import { Helmet } from "react-helmet";

function Access() {
  useEffect(() => {
    window.onload = updateGoogleMapCss;
    window.addEventListener('resize', updateGoogleMapCss);

    return () => window.removeEventListener('resize', updateGoogleMapCss);
  });

  // マップの横幅と同じ高さ（正方形）になるようにスタイルを設定
  const updateGoogleMapCss = (event) => {
    let mapWidth = $('.google-map-content').width();
    $('.google-map-content').css('height', mapWidth);
  }

  return (
    <div id="access">
      <Helmet>
        <link
          rel="canonical"
          href="https://www.necmos.jp/company-access" />
        <title>オフィスへの行き方 | Company | 株式会社Necmos</title>
        <meta
          name="description"
          content="代々木オフィスの住所、マップをご確認いただけます。" />
      </Helmet>

      <div className="access-title">
        <h3>Access</h3>
        <h4>オフィスへの行き方</h4>
      </div>

      <div className="access-layout">
        <div className="access-container">
          <iframe
            className="google-map-content"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.8155505716!2d139.69941121561214!3d35.681543437561565!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188cc6269452e9%3A0xbf16fa0cb45a9f54!2z5aSp57-U44Kq44OV44Kj44K55Luj44CF5pyoQU5ORVg!5e0!3m2!1sja!2sjp!4v1647003058701!5m2!1sja!2sjp"
            style={{border:0}}
            allowFullScreen="" />
          <h4>Necmos Office</h4>
          <div className="address-content">
            <img src={MapPin} />
            <p>〒151-0053 東京都渋谷区代々木1-30-14 天翔オフィス代々木ANNEX 206号室</p>
          </div>
          <a
            className="map-link"
            href="https://goo.gl/maps/7bFDNKHHz7w7RBAc6"
            target="_blank"
            rel="noopener noreferrer nofollow">
            Google Map<img src={ArrowUp} />
          </a>
        </div>
      </div>

      <div className="more-information">
        <h4 className="more-information-title">More Information</h4>
        <Card
          link="/company-profile"
          image={NcmsBackground}
          title="会社概要" />
        <Card
          link="/company-message"
          image={CeoImg}
          title="代表あいさつ" />
        <Card
          link="/company-culture"
          image={NcmsCulture}
          title="Necmosの文化" />
      </div>
    </div>
  );
}

export default Access;
