import '../styles/header.scss';
import NcmsLogo from '../images/Ncms-logo.svg';
import { HiOutlineHome } from "react-icons/hi";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import {MdKeyboardArrowRight} from 'react-icons/md';
import Link from '@mui/material/Link';
import React, { useState, useEffect } from "react";
import AccordionDown from '../images/accordion-down-for-header.svg';
import AccordionUp from '../images/accordion-up-pink.svg';
import AccordionRight from '../images/accordion-right.svg';
import AccordionRightPink from '../images/accordion-right-pink.svg';
import ContactIcon from '../images/header-contact-icon.svg';
import ArrowRightBlack from '../images/arrow-right-black.svg';
import ArrowRightPink from '../images/arrow-right-pink.svg';

function Header() {
  const topBreadcrumbs = [
    <Link href=""></Link>,
    <Link
      underline="hover"
      color="inherit"
      href="https://share.hsforms.com/1bwASZ7OpQ_ye-7H_Ax3xlwrrpdj"
      target="_blank"
      rel="noopener noreferrer nofollow">
      お問い合わせ
    </Link>
  ];
  const companyBreadcrumbs = [
    <Link underline="hover" color="inherit" href="/" className="home-icon"><HiOutlineHome /></Link>,
    <Link underline="hover" color="text.primary" href="/company" aria-current="page">会社情報</Link>
  ];
  const companyOverviewBreadcrumbs = [
    <Link underline="hover" color="inherit" href="/" className="home-icon"><HiOutlineHome /></Link>,
    <Link underline="hover" color="inherit" href="/company">会社情報</Link>,
    <Link underline="hover" color="text.primary" href="/company-profile" aria-current="page">会社概要</Link>
  ];
  const ceoMessageBreadcrumbs = [
    <Link underline="hover" color="inherit" href="/" className="home-icon"><HiOutlineHome /></Link>,
    <Link underline="hover" color="inherit" href="/company">会社情報</Link>,
    <Link underline="hover" color="text.primary" href="/company-message" aria-current="page">代表あいさつ</Link>
  ];
  const cultureBreadcrumbs = [
    <Link underline="hover" color="inherit" href="/" className="home-icon"><HiOutlineHome /></Link>,
    <Link underline="hover" color="inherit" href="/company">会社情報</Link>,
    <Link underline="hover" color="text.primary" href="/company-culture" aria-current="page">Necmosの文化</Link>
  ];
  const accessBreadcrumbs = [
    <Link underline="hover" color="inherit" href="/" className="home-icon"><HiOutlineHome /></Link>,
    <Link underline="hover" color="inherit" href="/company">会社情報</Link>,
    <Link underline="hover" color="text.primary" href="/company-access" aria-current="page">アクセス</Link>
  ];
  const servicesBreadcrumbs = [
    <Link underline="hover" color="inherit" href="/" className="home-icon"><HiOutlineHome /></Link>,
    <Link underline="hover" color="text.primary" href="/services" aria-current="page">事業内容</Link>
  ];
  const agentServiceBreadcrumbs = [
    <Link underline="hover" color="inherit" href="/" className="home-icon"><HiOutlineHome /></Link>,
    <Link underline="hover" color="inherit" href="/services">事業内容</Link>,
    <Link underline="hover" color="text.primary" href="/services-b2b" aria-current="page">B2E2B</Link>
  ]
  const DCServiceBreadcrumbs = [
    <Link underline="hover" color="inherit" href="/" className="home-icon"><HiOutlineHome /></Link>,
    <Link underline="hover" color="inherit" href="/services">事業内容</Link>,
    <Link underline="hover" color="text.primary" href="/services-DC" aria-current="page">dc</Link>
  ]
  const careersBreadcrumbs = [
    <Link underline="hover" color="inherit" href="/" className="home-icon"><HiOutlineHome /></Link>,
    <Link underline="hover" color="inherit" href="https://www.career.necmos.jp/">採用情報</Link>,
  ]

  const breadcrumbs = function() {
    const path = window.location.pathname;
    switch (path) {
      case '/':
        return topBreadcrumbs;
      case '/company':
        return companyBreadcrumbs;
      case '/company-profile':
        return companyOverviewBreadcrumbs;
      case '/company-message':
        return ceoMessageBreadcrumbs;
      case '/company-culture':
        return cultureBreadcrumbs;
      case '/company-access':
        return accessBreadcrumbs;
      case '/services':
        return servicesBreadcrumbs;
      case '/services-b2b':
        return agentServiceBreadcrumbs;
      case '/services-DC':
        return DCServiceBreadcrumbs;
      case '/careers':
        return careersBreadcrumbs; 
      default:
        return topBreadcrumbs;
    }
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorDC, setAnchorDC] = useState(null);
  const opendc = Boolean(anchorDC);
  const open = Boolean(anchorEl);
  const handleHover = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleHoverdc = (event) => {
    setAnchorDC(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClosedc = () => {
    setAnchorDC(null);
  };

  return (
    <header id="header">
      <div className="for-sp">
        <a href="/">
          <img src={NcmsLogo} />
        </a>
        <Breadcrumbs
          separator={<MdKeyboardArrowRight />}
          aria-label="breadcrumb">
            {breadcrumbs()}
        </Breadcrumbs>
      </div>

      {/* PCの画面 */}
      <div className="for-pc">
        <a href="/">
          <img src={NcmsLogo} />
        </a>

        <div className="link-lists">
          <a
            className={"large-link dropdown " + (open ? 'open' : 'close')}
            href="/company"
            onMouseEnter={handleHover}
            onMouseLeave={handleClose}
            onClick={handleClose} >
            会社情報 <img src={ open ? AccordionUp : AccordionDown} />
          </a>
          <a
            className={"large-link dc " + (opendc ? 'open' : 'close')}
            href="/services"
            onMouseEnter={handleHoverdc}
            onMouseLeave={handleClosedc}
            onClick={handleClosedc} >
            事業内容 <img src={ opendc ? AccordionUp : AccordionDown} />
          </a>
          <a
            className="large-link last"
            href="https://www.career.necmos.jp/"
            >
              採用情報
            <img
              className="arrow"
              src={ArrowRightBlack} />
            <img
              className="arrow-hover"
              src={ArrowRightPink} />
          </a>
          <a
            className="contact-icon"
            href="https://share.hsforms.com/1bwASZ7OpQ_ye-7H_Ax3xlwrrpdj"
            target="_blank"
            rel="noopener noreferrer nofollow">
            <img src={ContactIcon} />
          </a>

          {open &&
            <div
              className="dropdown-company-links"
              onMouseEnter={handleHover}
              onMouseLeave={handleClose} >
              <div className="link-set-1">
                <a
                  className="drop-downlink"
                  href="/company"
                  onClick={handleClose}>
                  <span>会社情報</span>
                  <img
                    className="black-arrow"
                    src={AccordionRight} />
                  <img
                    className="pink-arrow"
                    src={AccordionRightPink} />
                </a>
              </div>
              <div className="link-set-2">
                <a
                  className="drop-downlink"
                  href="/company-profile"
                  onClick={handleClose}>
                  <span>会社概要</span>
                  <img
                    className="black-arrow"
                    src={AccordionRight} />
                  <img
                    className="pink-arrow"
                    src={AccordionRightPink} />
                </a>
                <a
                  className="drop-downlink"
                  href="/company-message"
                  onClick={handleClose}>
                  <span>代表あいさつ</span>
                  <img
                    className="black-arrow"
                    src={AccordionRight} />
                  <img
                    className="pink-arrow"
                    src={AccordionRightPink} />
                </a>
                <a
                  className="drop-downlink"
                  href="/company-culture"
                  onClick={handleClose}>
                  <span>Necmosの文化</span>
                  <img
                    className="black-arrow"
                    src={AccordionRight} />
                  <img
                    className="pink-arrow"
                    src={AccordionRightPink} />
                </a>
                <a
                  className="drop-downlink"
                  href="/company-access"
                  onClick={handleClose}>
                  <span>オフィスへの行き方</span>
                  <img
                    className="black-arrow"
                    src={AccordionRight} />
                  <img
                    className="pink-arrow"
                    src={AccordionRightPink} />
                </a>
              </div>
            </div>
          }
          {opendc &&
            <div
              className="dropdown-DC-links"
              onMouseEnter={handleHoverdc}
              onMouseLeave={handleClosedc} >
              <div className="link-set-1">
                <a
                  className="drop-downlink"
                  href="/services"
                  onClick={handleClosedc}>
                  <span>事業内容</span>
                  <img
                    className="black-arrow"
                    src={AccordionRight} />
                  <img
                    className="pink-arrow"
                    src={AccordionRightPink} />
                </a>
              </div>
              <div className="link-set-2">
                <a
                  className="drop-downlink"
                  href="/services-b2b"
                  onClick={handleClosedc}>
                  <span>キャリア伴走型エージェント事業</span>
                  <img
                    className="black-arrow"
                    src={AccordionRight} />
                  <img
                    className="pink-arrow"
                    src={AccordionRightPink} />
                </a>
                <a
                  className="drop-downlink"
                  href="/services-DC"
                  onClick={handleClosedc}>
                  <span>デジタルクリエイティブ事業</span>
                  <img
                    className="black-arrow"
                    src={AccordionRight} />
                  <img
                    className="pink-arrow"
                    src={AccordionRightPink} />
                </a>
              </div>
            </div>
          }
        </div>
      </div>
    </header>
  );
}

export default Header;
