import '../styles/viewAllLink.scss';
import ArrowRight from '../images/arrow-right-blue.svg';
import DiagonalArrow from '../images/diagonal-arrow-blue.svg';

function ViewAllLink(props) {
  const setTargetBlank = function(isTargetBlank) {
    if (isTargetBlank) {
      return {
        target: '_blank',
        rel: "noopener noreferrer nofollow"
      }
    }
  };

  return (
    <a
      href={props.link}
      {...setTargetBlank(props.isDiagonal)}
      className="view-all-link">
      View all<img src={props.isDiagonal ? DiagonalArrow : ArrowRight} />
    </a>
  );
}

export default ViewAllLink;
