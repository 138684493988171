import React, {useEffect} from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Top from './top.js';
import CompanyInfo from './companyInfo.js';
import Service from './service.js';
import Employment from './employment.js';
import CompanyOverview from './companyOverview.js';
import CeoMessage from './ceoMessage.js';
import Culture from './culture.js';
import Access from './access.js';
import AgentService from './agentSercive.js';
import DCService from './DCService.js';

function MainContents() {
  // ページを付け足すときは、ここの配列にパスを追加する
  const registeredPaths = ['/', '/company', '/company-profile', '/company-message', '/company-culture', '/company-access', '/services', '/services-b2b', '/services-DC', '/careers']
  const redirectHandler = function(registeredPaths) {
    const currentPath = window.location.pathname;

    // 存在しないページにアクセスした場合は、トップページにリダイレクトする
    if (!registeredPaths.includes(currentPath)) {
      window.location.pathname = '/';
    }
  }

  useEffect(() => {
    redirectHandler(registeredPaths);
  })

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Top/>} />
          <Route path="/company" element={<CompanyInfo/>} />
          <Route path="/company-profile" element={<CompanyOverview/>} />
          <Route path="/company-message" element={<CeoMessage/>} />
          <Route path="/company-culture" element={<Culture/>} />
          <Route path="/company-access" element={<Access/>} />

          <Route path="/services" element={<Service/>} />
          <Route path="/services-b2b" element={<AgentService />} />
          <Route path="/services-DC" element={<DCService/>} />
          <Route path="/careers" element={<Employment/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default MainContents;
