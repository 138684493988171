import '../styles/necfree.scss';
import {IoMdClose} from 'react-icons/io';

function Necfree(props) {
  return (
    <div id="necfree-layout">
      <IoMdClose
        className="close-button"
        onClick={() => props.closeHandler()} />
      <h4>開発中...</h4>
      <a
        className="inquiry-button"
        href="https://share.hsforms.com/1bwASZ7OpQ_ye-7H_Ax3xlwrrpdj"
        target="_blank"
        rel="noopener noreferrer nofollow">
          ご相談はこちら
      </a>
    </div>
  );
}

export default Necfree;
