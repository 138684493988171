import '../styles/buttonWithArrow.scss';
import ArrowRightBlue from '../images/arrow-right-blue.svg';
import ArrowRightWhite from '../images/arrow-right-white.svg';
import DiagonalArrow from '../images/diagonal-arrow.svg';

function ButtonWithArrow(props) {
  const setTargetBlank = function(isTargetBlank) {
    if (isTargetBlank) {
      return {
        target: '_blank',
        rel: "noopener noreferrer nofollow"
      }
    }
  };

  const setArrow = function(bgColor) {
    if (props.isDiagonal) return DiagonalArrow;
    const arrow = bgColor == 'blue' ? ArrowRightWhite : ArrowRightBlue;
    return arrow
  }

  return (
    <a
      className={props.bg == 'blue' ? 'button-with-arrow bg-blue' : 'button-with-arrow bg-white'}
      href={props.link}
      {...setTargetBlank(props.targetBlank)}>
      {props.text} <img src={setArrow(props.bg)}/>
    </a>
  );
}

export default ButtonWithArrow;
