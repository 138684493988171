import '../styles/navigationMenu.scss';
import React, { useState } from "react";
import { HiOutlineHome } from "react-icons/hi";
import { BiBuildings } from "react-icons/bi";
import { MdDashboard, MdMenu } from "react-icons/md";
import { BsFillPersonFill } from "react-icons/bs";
import { IoIosSend } from "react-icons/io";
import Footer from './footer.js';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

function NavigationMenu() {
  const [state, setState] = useState(false)
  const openDrawer = () => {setState(!state);}
  const currentPath = window.location.pathname
  const topPath = ['/']
  const companyInfoPaths = ['/company', '/company-profile', '/company-message', '/company-culture', '/company-access']
  const servicesPaths = ['/services', '/services-b2b']
  const employmentPaths = ['https://www.career.necmos.jp/']
  const activeMarker = (menu) => {
    switch (menu) {
      case 'top':
        return topPath.includes(currentPath) ? 'active' : '';
      case 'companyInfo':
        return companyInfoPaths.includes(currentPath) ? 'active' : '';
      case 'services':
        return servicesPaths.includes(currentPath) ? 'active' : '';
      case 'employment':
        return employmentPaths.includes(currentPath) ? 'active' : '';
    }
  }

  return (
    <div id="menu-layout">
      <a href="/"
        className={activeMarker('top')}>
        <HiOutlineHome />
        <span>トップ</span>
      </a>
      <a href="/company"
        className={activeMarker('companyInfo')}>
        <BiBuildings />
        <span>会社情報</span>
      </a>
      <a href="/services"
        className={activeMarker('services')}>
        <MdDashboard />
        <span>事業内容</span>
      </a>
      <a href="https://www.career.necmos.jp/"
        className={activeMarker('employment')}>
        <BsFillPersonFill />
        <span>採用情報</span>
      </a>
      <a
        href="https://share.hsforms.com/1bwASZ7OpQ_ye-7H_Ax3xlwrrpdj"
        target="_blank"
        rel="noopener noreferrer nofollow" >
        <IoIosSend />
        <span>問い合わせ</span>
      </a>
      <a
        className="menu"
        onClick={() => openDrawer()}>
        <MdMenu />
        <span>メニュー</span>
      </a>

      <div id="menuDrawer">
        <SwipeableDrawer
          anchor="bottom"
          open={state}
          onClose={() => setState(false)}
          onOpen={() => setState(false)} >
          <Footer
          drawer={true}
          closeMenuHandler={() => setState(false)} />
        </SwipeableDrawer>
      </div>
    </div>
  );
}
export default NavigationMenu;
