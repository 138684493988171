import Card from './card.js';
import CeoSign from '../images/message-kubota-sign.png';
import CeoImg from '../images/ceo-message_kubota.webp';
import NcmsBackground from '../images/ncms-background.svg';
import NcmsCulture from '../images/culture.webp';
import AccessToOffice from '../images/access-to-office.png';
import '../styles/ceoMessage.scss';
import { Helmet } from "react-helmet"

function CeoMessage() {
  return (
    <div id="ceo-message">

      <Helmet>
        <link
          rel="canonical"
          href="https://www.necmos.jp/company-message" />
        <title>代表あいさつ | Company | 株式会社Necmos</title>
        <meta
          name="description"
          content="代表取締役 久保田泰一郎からのメッセージをご覧いただけます。" />
      </Helmet>

      <div className="company-info-title">
        <h3>CEO Message</h3>
        <h4>代表あいさつ</h4>
      </div>
      <div className="ceo-message-layout">
        <div className="ceo-message-container">
          <img
            className="ceo-img"
            src={CeoImg} />
          <p className="paragraph-m">“自分らしさが誰かのためになる世界の実現を目指して”</p>
          <p className="paragraph-m">“VUCAの時代”と称される現代において、まさに1人1人が自身のキャリア、そして人生について考え生きていくことが求められるようになってきました。弊社では在籍する社員のキャリアを一緒に考え伴走し、最適なキャリアを歩めるように、社員1人1人をプロデュースさせて頂きます。</p>
          <p className="paragraph-m">お客様の本質的な課題を解消することが、自身の幸福に繋がる。そのような人材をご提案させて頂くことこそが、最高のソリューションとなるよう努めて参ります。</p>
          <img
            className="sign"
            src={CeoSign} />
        </div>
      </div>

      <div className="more-information">
        <h4 className="more-information-title">More Information</h4>
        <Card
          link="/company-culture"
          image={NcmsCulture}
          title="Necmosの文化" />
        <Card
          link="/company-access"
          image={AccessToOffice}
          title="オフィスへの行き方" />
        <Card
          link="/company-profile"
          image={NcmsBackground}
          title="会社概要" />
      </div>
    </div>
  );
}

export default CeoMessage;
