import Card from './card.js';
import NcmsBackground from '../images/ncms-background.svg';
import CeoImg from '../images/ceo-message_kubota.webp';
import NcmsCulture from '../images/culture.webp';
import AccessToOffice from '../images/access-to-office.png';
import '../styles/companyInfo.scss';
import { Helmet } from "react-helmet"

function CompanyInfo() {
  return (
    <div id="company-info">

      <Helmet>
        <link
          rel="canonical"
          href="https://www.necmos.jp/company" />
        <title>Company | 株式会社Necmos</title>
        <meta
          name="description"
          content="Necmosの会社情報ページです。会社概要、代表あいさつ、Necmosの文化、オフィスへの行き方をご覧いただけます。" />
      </Helmet>

      <div className="company-info-title">
        <h3>Company</h3>
        <h4>会社情報</h4>
      </div>

      <div className="cards">
        <Card
          link="/company-profile"
          image={NcmsBackground}
          title="会社情報" />
        <Card
          link="/company-message"
          image={CeoImg}
          title="代表あいさつ" />
        <Card
          link="/company-culture"
          image={NcmsCulture}
          title="Necmosの文化" />
        <Card
          link="/company-access"
          image={AccessToOffice}
          title="オフィスへの行き方" />
      </div>
    </div>
  );
}

export default CompanyInfo;
