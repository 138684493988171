import '../styles/careerAccordion.scss';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import React, { useState } from "react";
import AccordionDown from '../images/accordion-down.svg';

function CareerAccordion(props) {
  const [expanded, setExpanded] = useState(true);

  return (
    <div className="career-accordion-content">
      <div className="for-sp">
        <Accordion
          className="career-accordion-layout"
          sx={{
            boxShadow: 'none',
            '&:before': {
              backgroundColor: 'transparent'
            }
          }}
          classes={
            {
              root: 'career-accordion-root',
              content: 'career-accordion-summary-content'
            }
          }>
          <AccordionSummary
            expandIcon={<img src={AccordionDown} />}
            aria-controls="panel1a-content"
            onClick={(e) => { setExpanded(!expanded) }}
            className="accordion-summary"
            classes={
              {
                root: 'career-accordion-summary-root',
                content: 'career-accordion-summary-content',
              }
            }>
            <h4 className="title"><img src={props.image} />{props.title}</h4>
          </AccordionSummary>
          <AccordionDetails>
            <p className="paragraph-m description">{props.text}</p>
          </AccordionDetails>
        </Accordion>
      </div>

      {/* PC用 */}
      <div className="for-pc">
        <div className="career-accordion-layout">
          <h4 className="title"><img src={props.image} />{props.title}</h4>
          <p className="paragraph-m description">{props.text}</p>
        </div>
      </div>
    </div>
  );
}

export default CareerAccordion;
