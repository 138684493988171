import './App.scss';
import MainContents from './components/mainContents.js';
import Header from './components/header.js';
import Footer from './components/footer.js';
import NavigationMenu from './components/navigationMenu.js';

function App() {
  return (
    <div className="App">
      <Header />
      <main>
        <MainContents />
      </main>
      <Footer />
      <NavigationMenu />
    </div>
  );
}

export default App;
