import '../styles/jobRolesLists.scss';
import {BsCircleFill} from 'react-icons/bs';
import CareerFieldButton from './careerFieldButton.js';

function JobRolesList() {
  return (
    <div className="job-role-lists-container">
      <div className="for-sp">
        <div className="jobs-role-lists">
          <h4 className="jobs-role-name"><BsCircleFill />開発エンジニア</h4>
          <CareerFieldButton
            link="https://jp.indeed.com/%E4%BB%95%E4%BA%8B?cmp=%E6%A0%AA%E5%BC%8F%E4%BC%9A%E7%A4%BENecmos&t=Web+%E9%96%8B%E7%99%BA%E3%82%A8%E3%83%B3%E3%82%B8%E3%83%8B%E3%82%A2&jk=6a4b2b5bc24dbd02&q=Necmos&vjs=3"
            content="フロントエンドエンジニア" />
          <CareerFieldButton
            link="https://jp.indeed.com/%E4%BB%95%E4%BA%8B?cmp=%E6%A0%AA%E5%BC%8F%E4%BC%9A%E7%A4%BENecmos&t=Web+%E3%82%A8%E3%83%B3%E3%82%B8%E3%83%8B%E3%82%A2&jk=ba7c47c88a9b11bc&q=%E6%A0%AA%E5%BC%8F%E4%BC%9A%E7%A4%BENecmos&vjs=3"
            content="バックエンドエンジニア" />
          <CareerFieldButton
            link="https://jp.indeed.com/%E4%BB%95%E4%BA%8B?cmp=%E6%A0%AA%E5%BC%8F%E4%BC%9A%E7%A4%BENecmos&t=%E3%82%A2%E3%83%97%E3%83%AA%E9%96%8B%E7%99%BA%E3%82%A8%E3%83%B3%E3%82%B8%E3%83%8B%E3%82%A2&jk=1ead95bab5968130&q=Necmos&vjs=3"
            content="アプリエンジニア" />
        </div>

        <div className="jobs-role-lists">
          <h4 className="jobs-role-name"><BsCircleFill />インフラエンジニア</h4>
          <CareerFieldButton
            link="https://jp.indeed.com/%E4%BB%95%E4%BA%8B?cmp=%E6%A0%AA%E5%BC%8F%E4%BC%9A%E7%A4%BENecmos&t=%E3%83%8D%E3%83%83%E3%83%88%E3%83%AF%E3%83%BC%E3%82%AF%E3%82%A8%E3%83%B3%E3%82%B8%E3%83%8B%E3%82%A2&jk=8e97c18a7ae0d000&q=%E6%A0%AA%E5%BC%8F%E4%BC%9A%E7%A4%BENecmos&vjs=3"
            content="ネットワークエンジニア" />
          <CareerFieldButton
            link="https://jp.indeed.com/%E4%BB%95%E4%BA%8B?cmp=%E6%A0%AA%E5%BC%8F%E4%BC%9A%E7%A4%BENecmos&t=%E3%82%B5%E3%83%BC%E3%83%90%E3%83%BC%E3%82%A8%E3%83%B3%E3%82%B8%E3%83%8B%E3%82%A2&jk=3bf8b4251f6bc4f9&q=%E6%A0%AA%E5%BC%8F%E4%BC%9A%E7%A4%BENecmos&vjs=3"
            content="サーバーエンジニア" />
        </div>

        <div className="jobs-role-lists">
          <h4 className="jobs-role-name"><BsCircleFill />プロジェクト管理</h4>
          <CareerFieldButton
            link="https://jp.indeed.com/%E4%BB%95%E4%BA%8B?cmp=%E6%A0%AA%E5%BC%8F%E4%BC%9A%E7%A4%BENecmos&t=IT+%E3%83%97%E3%83%AD%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88%E3%83%9E%E3%83%8D%E3%83%BC%E3%82%B8%E3%83%A3%E3%83%BC&jk=a7428b6b27a3f193&q=Necmos&vjs=3"
            content="プロジェクトマネージャー" />
        </div>

        <div className="jobs-role-lists">
          <h4 className="jobs-role-name"><BsCircleFill />デザイナー</h4>
          <CareerFieldButton
            link="https://jp.indeed.com/%E4%BB%95%E4%BA%8B?cmp=%E6%A0%AA%E5%BC%8F%E4%BC%9A%E7%A4%BENecmos&t=Web+%E3%83%87%E3%82%B6%E3%82%A4%E3%83%8A%E3%83%BC&jk=c4a40b8817c952a6&q=Necmos&vjs=3"
            content="UI/UXデザイナー" />
        </div>

        <div className="jobs-role-lists">
          <h4 className="jobs-role-name"><BsCircleFill />営業</h4>
          <CareerFieldButton
            link="https://jp.indeed.com/%E4%BB%95%E4%BA%8B?cmp=%E6%A0%AA%E5%BC%8F%E4%BC%9A%E7%A4%BENecmos&t=IT+%E4%BA%BA%E6%9D%90%E3%82%B3%E3%83%BC%E3%83%87%E3%82%A3%E3%83%8D%E3%83%BC%E3%82%BF%E3%83%BC&jk=5afae989164e90be&q=Necmos&vjs=3"
            content="ITキャリアアドバイザー" />
        </div>
      </div>

      {/* PC */}
      <div className="for-pc">
        <div className="left-content">
          <div className="jobs-role-lists">
            <div className="jobs-role-name"><BsCircleFill /><h4>開発エンジニア</h4></div>
            <div className="job-role-buttons">
              <CareerFieldButton
                link="https://jp.indeed.com/web%E9%96%8B%E7%99%BA%E3%82%A8%E3%83%B3%E3%82%B8%E3%83%8B%E3%82%A2%E3%83%95%E3%83%AD%E3%83%B3%E3%83%88%E3%82%A8%E3%83%B3%E3%83%89%E3%81%AE%E6%B1%82%E4%BA%BA%E6%83%85%E5%A0%B1-996072ace7969e1b"
                content="フロントエンドエンジニア" />
              <CareerFieldButton
                link="https://jp.indeed.com/web%E3%82%A8%E3%83%B3%E3%82%B8%E3%83%8B%E3%82%A2%E3%83%90%E3%83%83%E3%82%AF%E3%82%A8%E3%83%B3%E3%83%89%E3%81%AE%E6%B1%82%E4%BA%BA%E6%83%85%E5%A0%B1-467d2cd9c38af2c8"
                content="バックエンドエンジニア" />
              <CareerFieldButton
                link="https://jp.indeed.com/%E3%82%A2%E3%83%97%E3%83%AA%E9%96%8B%E7%99%BA%E3%82%A8%E3%83%B3%E3%82%B8%E3%83%8B%E3%82%A2%E3%81%AE%E6%B1%82%E4%BA%BA%E6%83%85%E5%A0%B1-94a9dd27afc7b170"
                content="アプリエンジニア" />
            </div>
          </div>

          <div className="jobs-role-lists">
            <div className="jobs-role-name"><BsCircleFill /><h4>プロジェクト管理</h4></div>
            <div className="job-role-buttons">
              <CareerFieldButton
                link="https://jp.indeed.com/it%E3%83%97%E3%83%AD%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88%E3%83%9E%E3%83%8D%E3%83%BC%E3%82%B8%E3%83%A3%E3%83%BCpm%E3%81%AE%E6%B1%82%E4%BA%BA%E6%83%85%E5%A0%B1-836d86b2fa351a69"
                content="プロジェクトマネージャー" />
            </div>
          </div>
        </div>

        <div className="right-content">
          <div className="jobs-role-lists">
            <div className="jobs-role-name"><BsCircleFill /><h4 className="jobs-role-name">インフラエンジニア</h4></div>
            <div className="job-role-buttons">
              <CareerFieldButton
                link="https://jp.indeed.com/%E3%83%8D%E3%83%83%E3%83%88%E3%83%AF%E3%83%BC%E3%82%AF%E3%82%A8%E3%83%B3%E3%82%B8%E3%83%8B%E3%82%A2%E3%81%AE%E6%B1%82%E4%BA%BA%E6%83%85%E5%A0%B1-4f944285374dfcc2"
                content="ネットワークエンジニア" />
              <CareerFieldButton
                link="https://jp.indeed.com/%E3%82%B5%E3%83%BC%E3%83%90%E3%83%BC%E3%82%A8%E3%83%B3%E3%82%B8%E3%83%8B%E3%82%A2%E3%81%AE%E6%B1%82%E4%BA%BA%E6%83%85%E5%A0%B1-d14655389f9dab97"
                content="サーバーエンジニア" />
            </div>
          </div>

          <div className="jobs-role-lists">
            <div className="jobs-role-name"><BsCircleFill /><h4>デザイナー</h4></div>
            <div className="job-role-buttons">
              <CareerFieldButton
                link="https://jp.indeed.com/uiux%E3%83%87%E3%82%B6%E3%82%A4%E3%83%8A%E3%83%BC%E3%81%AE%E6%B1%82%E4%BA%BA%E6%83%85%E5%A0%B1-72fc4ecf3a9955ee"
                content="UI/UXデザイナー" />
            </div>
          </div>

          <div className="jobs-role-lists">
            <div className="jobs-role-name"><BsCircleFill /><h4>営業</h4></div>
            <div className="job-role-buttons">
              <CareerFieldButton
                link="https://jp.indeed.com/it%E3%82%AD%E3%83%A3%E3%83%AA%E3%82%A2%E3%82%A2%E3%83%89%E3%83%90%E3%82%A4%E3%82%B6%E3%83%BC%E3%81%AE%E6%B1%82%E4%BA%BA%E6%83%85%E5%A0%B1-259cd10aabeaab61"
                content="ITキャリアアドバイザー" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JobRolesList;
