import '../styles/dcService.scss';
// 以下にイメージをインポートする。
import DCServiceTop from '../images/dc-cover.svg';
import ButtonWithArrow from './buttonWithArrow.js';
import {BsCircleFill} from 'react-icons/bs';
import { Helmet } from "react-helmet";
import CareerAccordion from "./careerAccordion.js";
import EgnIcon from '../images/dc-icon-01.svg';
import MoneyIconUp from '../images/dc-icon-02.svg';
import BracketsIcon from '../images/dc-icon-03.svg';
import CompassIcon from '../images/dc-icon-04.svg';
import LayoutIcon from '../images/dc-icon-05.svg';

function dcService() {
  return (
    <div id="DC-service">

      <Helmet>
        <link
          rel="canonical"
          href="https://www.necmos.jp/services-DC" />
        <title>デジタルクリエイティブ事業 | Services | 株式会社Necmos</title>
        <meta
          name="description"
          content="単価連動型の給与体系を採用し、個人の描くキャリアをサポートする体制のもと、お客様のニーズに最適な、自走力の高いIT人材を提供します。" />
      </Helmet>

      <div className="services-title">
        <h3>デジタル<br />クリエイティブ事業</h3>
      </div>
      <div className="dc-service-layout">
        <div className="dc-service-container">
          <img
            className="dc-service-img"
            src={DCServiceTop} />

          <div className="content">
            <h4 className="content-h4">サービス概要</h4>
            <h3>デジタルクリエイティブ事業とは</h3>
            <p className="dc-service-description">
              企業の価値を届けるべき人に届けるためのマーケティング戦略、
              他社との差別化を図る訴求力の高いクリエイティブ制作など、企業と社会、
              ユーザーとの永続的な関係性構築を目指した提案を行います。
            </p>

            <h4 className="dc-plan"><BsCircleFill />選べる二つのプラン</h4>
              <div className="dc-plan-container">
                <div className="dc-plan-A">
                  <div className="dc-plan-title-block">
                    <h5 className="dc-plan-title">
                    PlanA
                    </h5>
                    <div className="under-line-blue"></div>
                  </div>
                  <p className="dc-plan-caption">
                  内容：完全カスタマイズのシステム・アプリ開発<br/>
                  金額：150万〜<br/>
                  期間：2 or 3 ヶ月〜
                  </p>
                </div>
                <div className="dc-plan-B">
                  <div className="dc-plan-title-block">
                    <h5 className="dc-plan-title">
                    PlanB
                    </h5>
                    <div className="under-line-red"></div>
                  </div>
                  <p className="dc-plan-caption">
                  内容：保守コストの低いWebサイト制作<br/>
                  金額：50万〜<br/>
                  期間：1 ヶ月〜
                  </p>
                </div>
              </div>

            <h4 className="dc-plan"><BsCircleFill />事業領域</h4>
              <div className="dc-area-container-up">
              <a className="dc-area-button">HP制作</a>
              <a className="dc-area-button">ECサイト構築</a>
              <a className="dc-area-button">LP制作</a>
              <a className="dc-area-button">メディア構築</a>
              <a className="dc-area-button">web・スマホアプリ開発</a>
              <a className="dc-area-button">CMS導入</a>
              <a className="dc-area-button">アクセス解析</a>
              <a className="dc-area-button">SEO対策</a>
              <a className="dc-area-button">UI設計・デザイン</a>
              <a className="dc-area-button">ユーザー体験設計</a>
              </div>
            <h4 className="dc-plan"><BsCircleFill />担当工程</h4>
              <div className="dc-area-container-down">
              <a className="dc-area-button">企画</a>
              <a className="dc-area-button">課題分析</a>
              <a className="dc-area-button">マーケティング</a>
              <a className="dc-area-button">要件定義・設計</a>
              <a className="dc-area-button">ディレクション（制作代行）</a>
              <a className="dc-area-button">コーディング</a>
              <a className="dc-area-button">マークアップ</a>
              <a className="dc-area-button">デザイン</a>
              <a className="dc-area-button">運用・保守</a>
              <a className="dc-area-button">グロース支援</a>
              <a className="dc-area-button">テスト検証</a>
              </div>
            <ButtonWithArrow
              link="https://share.hsforms.com/1bwASZ7OpQ_ye-7H_Ax3xlwrrpdj"
              targetBlank={true}
              text="ご相談はこちらから"
              bg="blue" />
          </div>
        </div>
      </div>

      <div className="service-detail-layout">
        <div className="service-detail-container">
          <div className="content">
            <h4 className="content-h4">Necmosの強み</h4>
              <div className="accordion-container">
                <CareerAccordion
                  title="経験豊富な技術者が在籍"
                  image={EgnIcon}
                  text="大手やスタートアップ、ベンチャー企業など、様々なバックグラウンドで開発経験を豊富に積んだエンジニアが在籍しているため、品質は自信を持って保証いたします。
※弊社では受託開発だけでなく、自社サービスの開発や、１人称〜チーム体制での技術提供サービスも行っております。" />
                <CareerAccordion
                  title="完全パーソナライズでご提案"
                  image={MoneyIconUp}
                  text="お客様ごとに深いヒアリングを行い、理想のイメージに沿ったサイトを提供いたします。また、ご予算の範囲内で目的に応じたサイトのご提案もさせていただきます。" />
              <CareerAccordion
                  title="運用・保守のしやすい納品物"
                  image={BracketsIcon}
                  text="貴社のリソースや技術力とご相談しながらCMSの導入、ノーコードツールでの作成にも対応しております。
また、プロジェクト完了後、弊社にて継続保守を行う事も可能なため、納品後もご安心して利用いただけます。" />
              <CareerAccordion
                  title="進行ディレクションもお任せ"
                  image={CompassIcon}
                  text="プロジェクトの要件整理から、WBSの作成、進行スケジュールの策定など、プロジェクト終了までの一連フローのディレクションもお任せいただけます。" />
              <CareerAccordion
                  title="ユーザー体験を考慮したUI/UX設計"
                  image={LayoutIcon}
                  text="Webサイトやアプリケーションがどのように使われるか、サービスを通して提供できる顧客体験について、お客様と深く議論し、ユーザーにとって最も使いやすいUI/UX設計を行います。" />
            </div>           
            <ButtonWithArrow
              link="https://share.hsforms.com/1bwASZ7OpQ_ye-7H_Ax3xlwrrpdj"
              targetBlank={true}
              text="ご相談はこちらから"
              bg="blue" />
          </div>
        </div>
      </div>

      <div className="service-process-layout">
        <div className="service-process-container">
          <div className="content">
            <h4 className="content-h4 service-process-heading"><BsCircleFill />サービスの流れ</h4>

            <div className="step-lists">
              <div className="step-container">
                <h5>ー STEP 1</h5>
                <h3>お問い合わせ・ヒアリング</h3>
                <p>最適なプランのご提案のため、プロジェクトの全体像について、ヒアリングのお時間をいただきます。</p>
              </div>
              <div className="step-container">
                <h5>ー STEP 2</h5>
                <h3>プランのご提案</h3>
                <p>ヒアリング内容をもとに貴社に最もマッチするプラン・お見積り・概算スケジュールをご提案いたします。</p>
              </div>
              <div className="step-container">
                <h5>ー STEP 3</h5>
                <h3>契約締結・キックオフ</h3>
                <p>納品形式や詳細を定めた上で契約書を締結。プロジェクトの範囲や体制をすり合わせるためのキックオフを行います。</p>
              </div>
              <div className="step-container">
                <h5>ー STEP 4</h5>
                <h3>要件定義・設計</h3>
                <p>サービスの目的や、ターゲットとなるユーザー情報をもとに、開発前に必要な情報を揃えた上で、具体的な要件定義と設計を行います。</p>
              </div>
              <div className="step-container">
                <h5>ー STEP 5</h5>
                <h3>デザイン制作</h3>
                <p>ビジネスインパクトを考慮しつつも、「 誰に向けてのデザインなのか」、ターゲットとなるユーザーを常に意識したデザインをご提案。マイクロインタラクションなど細部までこだわりを持って制作いたします。</p>
              </div>
              <div className="step-container">
                <h5>ー STEP 6</h5>
                <h3>開発・マークアップ</h3>
                <p>フレームワークや開発の最新トレンドを把握した上で、客観的かつ合理的な技術選定を行います。また、貴社サービスの描く運用体制や改善活動を加味した開発のベストプラクティスを追求します。</p>
              </div>
              <div className="step-container">
                <h5>ー STEP 7</h5>
                <h3>テスト・リリース</h3>
                <p>想定した動作が問題なく実行されるか、様々なデバイスや環境でテストを行います。最終確認完了後、ご指定の営業日にリリースいたします。</p>
              </div>
              <div className="step-container">
                <h5>ー STEP 8</h5>
                <h3>運用・保守・改善支援</h3>
                <p>ご要望に応じて、運用保守や改善支援に特化したチームを社内にて組成し、リリース後のメンテナンスや改善活動の支援をいたします。</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default dcService;
