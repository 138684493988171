import '../styles/culture.scss';
import CultureTop from '../images/culture.webp';
import {BsCircleFill} from 'react-icons/bs'
import Card from './card.js';
import CeoImg from '../images/ceo-message_kubota.webp';
import NcmsBackground from '../images/ncms-background.svg';
import AccessToOffice from '../images/access-to-office.png';
import { Helmet } from "react-helmet"
import CareerAccordion from './careerAccordion.js';
import careerMessageImg1 from '../images/career-message-01.svg';
import careerMessageImg2 from '../images/career-message-02.svg';
import careerMessageImg3 from '../images/career-message-03.svg';
import careerMessageImg4 from '../images/career-message-04.svg';
import careerMessageImg5 from '../images/career-message-05.svg';
import careerMessageImg6 from '../images/career-message-06.svg';
import careerMessageImg7 from '../images/career-message-07.svg';

function Culture() {
  return (
    <div id="culture">

      <Helmet>
        <link
          rel="canonical"
          href="https://www.necmos.jp/company-culture" />
        <title>Necmosの文化 | Company | 株式会社Necmos</title>
        <meta
          name="description"
          content="Necmosが大切にしている価値観、ミッション、行動指針をご紹介します。理念は、「”たがい”を愛そう /love the differences」です。" />
      </Helmet>

      <div className="company-info-title">
        <h3>Culture</h3>
        <h4>Necmosの文化</h4>
      </div>

      <div className="culture-layout">
        <div className="culture-top-contents">
          <div className="culture-top-container">
            <img
              className="culture-top-img"
              src={CultureTop} />
            <div className="content">
              <h3><BsCircleFill className="circle-blue" />Philosophy</h3>
              <h4>“たがい”を愛そう</h4>
              <h5>love the differences</h5>
              <p className="paragraph-m">
                この先どれだけの時が流れたとしても決して変わらない弊社の普遍的な想いをPhilosophyとして位置づけました。
                “たがい”とは、「互い」であり「違い」のことです。
                この世には誰一人として同じ人間は存在しません。たとえ同じ経験をしてきたとしても、事象の捉え方は異なり、それぞれが価値観や考え方を人生の中で醸成していきます。
                築き上げてきた「自分らしさ」を認識しながら、また磨き続けながら人は社会の中で生きていきます。
                しかし、「自分らしさ」を認識するためには、必ずそれを認め、肯定することのできる他者が必要不可欠です。
                私達は、一人ひとりが持つ「自分らしさ」を大切にし、それと同様に他者の持つ「あなたらしさ」を大切にするという概念を最も重要な意義として掲げ、事業運営に取り組んで参ります。
              </p>
            </div>
            <div className="content">
              <h3><BsCircleFill className="circle-blue" />Mission</h3>
              <h4>自分らしさが誰かのためになる世界を実現する</h4>
              <h5>Create a world where being yourself is being for someone else.</h5>
              <p className="paragraph-m">
                少子高齢化、社内システムの老朽化、コロナ禍などによって労働生産性の向上が急がれる現代社会。
                Necmosでは、人材ひとりひとりの個性とキャリアに重点を置くことで、ワークエンゲージメント、ライフエンゲージメントの最大化を図ります。
                また、クライアントの抱える様々な課題に対して、最適な人材をプロジェクトベースで供給していくことで、流動的な働き方を推進し、社会全体の労働生産性の向上に寄与します。
                働く人々のエンゲージメント向上とクライアントの本質的な課題解決を両立することで、自分らしさが誰かのためになる世界を実現して参ります。
              </p>
            </div>
          </div>
        </div>

        <div className="credo-layout">
          <div className="credo-container">
            <h3 className="credo"><BsCircleFill />Credo</h3>
            <div className="credo-content-lists">
              <div className="credo-content">
                <h4>自分の可能性を信じよう</h4>
                <h5>Believe in Yourself</h5>
                <p className="paragraph-m">
                  時には、自分に何ができるのかを考えることは大事な意味を持つこともありますが、将来的に自分は何ができるようになりたいのかを考えることの方が重要です。
                  過去ではなく未来について考えていくためには、まず自分の可能性を信じるという前提が必要不可欠です。
                </p>
              </div>
              <div className="credo-content">
                <h4>プロフェッショナルでいよう</h4>
                <h5>Be Professional</h5>
                <p className="paragraph-m">
                  未経験だから。初日だから。スキルが無いから。
                  といった言い訳を見つけようと思えばいくらでも見つけられます。
                  ベストを尽くすのは当たり前。自らの仕事に責任を持ち、課題解決に向けて成果主義で仕事をしていくことを大切にしています。
                </p>
              </div>
              <div className="credo-content">
                <h4>挑戦を讃えよう</h4>
                <h5>Give it a try</h5>
                <p className="paragraph-m">
                  人が失敗しているところを見て笑う行為を弊社のカルチャーは嫌います。
                  努力する過程で人は挑戦し、必ず失敗を経験します。失敗を恐れず、挑戦し続けることが大切です。
                  諦めずアグレッシブに挑戦する人の姿は他者に勇気を与えてくれます。挑戦者へのリスペクトを持ち、讃えることで、更なる挑戦への一歩を踏み出していくことができます。
                </p>
              </div>
              <div className="credo-content">
                <h4>シェアしよう</h4>
                <h5>Just Share it</h5>
                <p className="paragraph-m">
                  自分が感じた課題、受け取った気持ちや感想、積み上げてきた経験、これらを積極的にシェアすることを大切にしています。
                  ナレッジを共有し合うことで、組織の成長を加速させることができます。
                  加えて、コロナ禍によってコミュニケーションが取りづらくなってきた現代において、積極的に他者と関わる接点を持つことが、組織だけでなく個人の感情やあらゆる所で、ポジティブな作用をもたらします。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="more-information">
        <h4 className="more-information-title">More Information</h4>
        <Card
          link="/company-access"
          image={AccessToOffice}
          title="オフィスへの行き方" />
        <Card
          link="/company-profile"
          image={NcmsBackground}
          title="会社概要" />
        <Card
          link="/company-message"
          image={CeoImg}
          title="代表あいさつ" />
      </div>
    </div>
  );
}

export default Culture;
